<template>
  <information-app-modal v-model="model" button-title="Continue" @close="closeModal" no-icon>
    <template #header>
      <IconPhoneRed />
    </template>
    <h1 class="title">{{ $t('ContactUsModal.TitleText').value }}</h1>

    <p>
      {{ $t('ContactUsModal.ContentText').value }}
    </p>

    <template #footer>
      <AppButton @click="onHelpCenterClick">
        {{ $t('ContactUsModal.GetInTouchButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="closeModal">
        {{ $t('ContactUsModal.CloseButton').value }}
      </AppButton>
    </template>
  </information-app-modal>
</template>

<script>


import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'
import { onBeforeMount, ref } from '@vue/composition-api'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import IconPhoneRed from '@galileo/assets/icons/vue/IconPhoneRed.vue'


import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

import { useI18nStore,useAppStore,useAnalyticsStore  } from '@galileo/stores'


export default {
  name: 'BankPaymentNotVerifiedCorp',
  components: {
    AppButton,
    InformationAppModal,
    IconPhoneRed,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isSendMoney: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()

    const closeModal = () => {
      model.value = false
    }

    const onHelpCenterClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.HOW_DO_I_UPDATE_MY_ACCOUNT_DETAILS,
        article: HelpDesk[HelpDeskProperties.HOW_DO_I_UPDATE_MY_ACCOUNT_DETAILS],
      })
    }

    onBeforeMount(() => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.MANUAL_PAYMENT_METHOD_PROMPT_DISPLAYED,
        traits: {
          location: props.isSendMoney ? SEGMENT_LOCATIONS.SEND_MONEY : SEGMENT_LOCATIONS.ACCOUNT,
        },
      })
    })

    return {
      model,
      $t,
      closeModal,
      ICON_TYPE,
      onHelpCenterClick,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
